import { ExclamationCircleFilled, CheckCircleFilled } from "@ant-design/icons";
import { notification } from "antd";

export class ApiError extends Error {
  constructor(
      public status: number,
      message: string,
      public errors?: string[]
  ) {
    super(message);
    this.name = "ApiError";
    Object.setPrototypeOf(this, ApiError.prototype);
  }
}

interface RequestOptions {
  skipClientHeader?: boolean;
  customHeaders?: Record<string, string>;
  successMessage?: string;
}

export abstract class ClubBookingApiClient {
  protected readonly clubbookingApiUrl: string;
  private readonly getToken: () => Promise<string>;
  private readonly clientId: string | null;

  constructor(getToken: () => Promise<string>, clientId: string | null) {
    this.clubbookingApiUrl = process.env.REACT_APP_CLUBBOOKING_API_URL || "";
    this.getToken = getToken;
    this.clientId = clientId;
  }

  private handleError(error: ApiError) {
    const description =
        error.errors && error.errors.length > 0 ? (
            <ul className="list-disc pl-4">
              {error.errors.map((err, index) => (
                  <li key={index}>{err}</li>
              ))}
            </ul>
        ) : (
            error.message
        );

    notification.error({
      message: "Error",
      description,
      placement: "topRight",
      icon: <ExclamationCircleFilled style={{ color: "#ff4d4f" }} />,
      className: "bg-white border border-red-100 shadow-lg",
      duration: 10,
    });
  }

  private handleSuccess(message: string) {
    notification.success({
      message: "Success",
      description: message,
      placement: "topRight",
      icon: <CheckCircleFilled style={{ color: "#52c41a" }} />,
      className: "bg-white border border-green-100 shadow-lg",
    });
  }

  protected async getHeaders(options?: RequestOptions): Promise<Headers> {
    const token = await this.getToken();
    const headers = new Headers({
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      ...options?.customHeaders,
    });

    if (!options?.skipClientHeader && this.clientId) {
      headers.set("X-Client-Id", this.clientId);
    }

    return headers;
  }

  private buildUrl(endpoint: string, params?: Record<string, string | number>): string {
    const url = new URL(`${this.clubbookingApiUrl}${endpoint}`);

    if (params) {
      Object.entries(params).forEach(([key, value]) => {
        if (value !== undefined && value !== null) {
          url.searchParams.append(key, value.toString());
        }
      });
    }

    return url.toString();
  }

  protected async get<T>(
      endpoint: string,
      params?: Record<string, string | number>,
      options?: RequestOptions
  ): Promise<T> {
    const headers = await this.getHeaders(options);
    const url = this.buildUrl(endpoint, params);

    try {
      const response = await fetch(url, { headers });

      if (!response.ok) {
          const errorText = await response.text();
          let errorMessage: string;
          try {
            const errorData = JSON.parse(errorText);
            errorMessage = errorData.message || errorData || errorText;
          } catch {
            errorMessage = errorText;
          }
          throw new ApiError(
              response.status,
              errorMessage,
              Array.isArray(errorMessage) ? errorMessage : undefined
          );
      }

      return response.json();
    } catch (error) {
      this.handleError(error as ApiError);
      throw error;
    }
  }

  protected async post<T = void>(
      endpoint: string,
      data?: any,
      options?: RequestOptions
  ): Promise<T> {
    const headers = await this.getHeaders(options);
    try {
      const response = await fetch(`${this.clubbookingApiUrl}${endpoint}`, {
        method: "POST",
        headers,
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const errorText = await response.text();
        let errorMessage: string;
        try {
          const errorData = JSON.parse(errorText);
          errorMessage = errorData.message || errorData || errorText;
        } catch {
          errorMessage = errorText;
        }

        throw new ApiError(
            response.status,
            errorMessage,
            Array.isArray(errorMessage) ? errorMessage : undefined
        );
      }

      this.handleSuccess(options?.successMessage || "Success!");

      if (response.status === 204 || response.headers.get("content-length") === "0") {
        return undefined as T;
      }

      const contentType = response.headers.get("content-type");
      if (contentType && contentType.includes("application/json")) {
        try {
          return await response.json();
        } catch {
          return undefined as T;
        }
      }

      return undefined as T;
    } catch (error) {
      if (error instanceof ApiError) {
        this.handleError(error);
      } else {
        this.handleError(new ApiError(500, "An unexpected error occurred"));
      }
      throw error;
    }
  }

  protected async put<T = void>(
      endpoint: string,
      data?: any,
      options?: RequestOptions
  ): Promise<T> {
    const headers = await this.getHeaders(options);
    try {
      const response = await fetch(`${this.clubbookingApiUrl}${endpoint}`, {
        method: "PUT",
        headers,
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new ApiError(
            response.status,
            errorData.message || response.statusText,
            errorData.errors
        );
      }

      this.handleSuccess(options?.successMessage || "Success!");

      return response.json();
    } catch (error) {
      if (error instanceof ApiError) {
        this.handleError(error);
      } else {
        this.handleError(new ApiError(500, "An unexpected error occurred"));
      }
      throw error;
    }
  }

  protected async delete<T = void>(
      endpoint: string,
      options?: RequestOptions
  ): Promise<T> {
    const headers = await this.getHeaders(options);
    try {
      const response = await fetch(`${this.clubbookingApiUrl}${endpoint}`, {
        method: "DELETE",
        headers,
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new ApiError(
            response.status,
            errorData.message || response.statusText,
            errorData.errors
        );
      }

      this.handleSuccess(options?.successMessage || "Success!");

      return response.json();
    } catch (error) {
      if (error instanceof ApiError) {
        this.handleError(error);
      } else {
        this.handleError(new ApiError(500, "An unexpected error occurred"));
      }
      throw error;
    }
  }
}