import React from 'react';
import { Result, Button } from 'antd';
import { useNavigate } from 'react-router-dom';

export const UnauthorizedPage: React.FC = () => {
    const navigate = useNavigate();

    return (
        <Result
            status="403"
            title="Unauthorized"
            subTitle="Sorry, you don't have access to this page."
            extra={
                <Button type="primary" onClick={() => navigate('/')}>
                    Back to Home
                </Button>
            }
        />
    );
};