import {useState, useEffect} from 'react';
import {useAuth0} from '@auth0/auth0-react';
import {PermissionsService} from "../../services/permissions/PermissionsService";
import {UserPermissions} from "../../model/members/UserPermissions";

export const usePermissions = (): UserPermissions => {
    const defaultPermissions: UserPermissions = {
        isLoading: true,
        member: {
            name: '',
            username: '',
            dateOfBirth: new Date(),
            emailAddress: '',
            addressLine1: '',
            addressLine2: '',
            townCity: '',
            countyState: '',
            postZipCode: '',
            phoneNumber: '',
            membershipId: '',
            specialOffers: false,
            status: 'Unknown',
        },
        authUser: {
            emailAddress: '',
            auth0Id: '',
            roles: [],
            permissions: [],
            clients: [],
        },
        permissions: [],
    };
    const {getAccessTokenSilently, isAuthenticated, user} = useAuth0();
    const [permissions, setPermissions] = useState<UserPermissions>(defaultPermissions);

    useEffect(() => {
      let isMounted = true;
      const fetchPermissions = async () => {
          if (!isAuthenticated) {
              setPermissions(prev => ({...prev, isLoading: false}));
              return;
          }

          try {
              const permissionsService = new PermissionsService(
                  getAccessTokenSilently,
                  localStorage.getItem("currentClientName") ?? null
              );

              const userPermissions = await permissionsService.getUserPermissions();
              if (isMounted) {
                  setPermissions({
                      ...userPermissions,
                      isLoading: false
                  });
              }
          } catch (error) {
              if (isMounted) {
                  setPermissions(defaultPermissions);
              }
          }
      };

      fetchPermissions();
      return () => {
          isMounted = false;
      };
  }, [getAccessTokenSilently, isAuthenticated, user?.sub]);


    return permissions;
};