import React from 'react';
import {PermissionsService} from "./PermissionsService";
import {useAuth0} from "@auth0/auth0-react";
import { useClient } from '../../common/ClientProvider';

interface PermissionsServiceProviderProps {
    children: React.ReactNode;  // Add this line
}

// Create a context for the booking service
export const PermissionsServiceContext = React.createContext<PermissionsService | undefined>(undefined);

// Create a provider component for the booking service
export const PermissionsServiceProvider: React.FC<PermissionsServiceProviderProps> = ({ children }) => {
    const { getAccessTokenSilently } = useAuth0();
    const { currentClientId } = useClient();

    const service = React.useMemo(() => {
        return new PermissionsService(getAccessTokenSilently, currentClientId);
    }, [getAccessTokenSilently, currentClientId]);

    return (
        <PermissionsServiceContext.Provider value={service}>
            {children}
        </PermissionsServiceContext.Provider>
    );
};

// Create a hook to use the booking service
export const usePermissionsService = (): PermissionsService => {
    const context = React.useContext(PermissionsServiceContext);
    if (context === undefined) {
        throw new Error('usePermissionsService must be used within a MemberServiceProvider');
    }
    return context;
};