export const MemberPermissions = {
    ReadMember: 'members:read',
    CreateMember: 'members:create',
    UpdateMember: 'members:update',
    ReadAllMembers: 'members:read-all',
    SubmitNewMemberForm: 'members:newmember'
};

export const BookingPermissions = {
    Read: 'bookings:read',
    Create: 'bookings:create',
    Edit: 'bookings:edit',
    Cancel: 'bookings:cancel'
};

export const AdminPermissions = {
    Access: 'admin:access',
    FullAccess: 'admin:full',
    FortiusAdmin: 'admin:fortius',
    ClubAdmin: 'admin:club'
};

export const PaymentPermissions = {
    ReadPayments: 'payments:read',
    CreatePayments: 'payments:create',
    UpdatePayments: 'payments:update',
    DeletePayments: 'payments:delete'
};

export const AllPermissions = {
    ...MemberPermissions,
    ...BookingPermissions,
    ...AdminPermissions,
    ...PaymentPermissions
}