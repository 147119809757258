import React from 'react';
import { Select, Space, Typography } from 'antd';
import { useClient } from "./ClientProvider";

const { Text } = Typography;

export const ClientSelector: React.FC = () => {
    const { currentClientId, setCurrentClientId, availableClients } = useClient();

    // Only show selector if user has multiple clients or is super admin
    if (availableClients.length <= 1) {
        return null;
    }

    return (
        <div style={{
            position: 'absolute',
            top: '16px',
            right: '24px',
            zIndex: 1000
        }}>
            <Space align="center">
                <Text strong style={{ color: '#fff' }}>Club:</Text>
                <Select
                    value={currentClientId}
                    onChange={setCurrentClientId}
                    style={{ 
                        width: 200,
                        marginLeft: '8px'
                    }}
                    options={availableClients.map(client => ({
                        label: client,
                        value: client
                    }))}
                    dropdownMatchSelectWidth={false}
                />
            </Space>
        </div>
    );
};