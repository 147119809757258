import React from 'react';
import { ClientService } from "./ClientService";
import { useAuth0 } from "@auth0/auth0-react";
import { useClient } from '../../common/ClientProvider';

interface ClientServiceProviderProps {
    children: React.ReactNode;
}

// Create a context for the client service
export const ClientServiceContext = React.createContext<ClientService | undefined>(undefined);

// Create a provider component for the client service
export const ClientServiceProvider: React.FC<ClientServiceProviderProps> = ({ children }) => {
    const { getAccessTokenSilently } = useAuth0();
    const { currentClientId } = useClient();

    // Create service instance
    const service = React.useMemo(() => {
        // When used for initial client fetch, currentClientId might be null
        // ClientService can handle null clientId for the getAvailableClients call
        return new ClientService(getAccessTokenSilently, currentClientId);
    }, [getAccessTokenSilently, currentClientId]);

    return (
        <ClientServiceContext.Provider value={service}>
            {children}
        </ClientServiceContext.Provider>
    );
};

// Create a hook to use the client service
export const useClientService = (): ClientService => {
    const context = React.useContext(ClientServiceContext);
    if (context === undefined) {
        throw new Error('useClientService must be used within a ClientServiceProvider');
    }
    return context;
};