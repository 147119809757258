import React from 'react';
import {BookingService} from "./BookingService";
import {useAuth0} from "@auth0/auth0-react";
import { useClient } from '../../common/ClientProvider';

interface BookingServiceProviderProps {
    children: React.ReactNode;  // Add this line
}

// Create a context for the booking service
export const BookingServiceContext = React.createContext<BookingService | undefined>(undefined);

// Create a provider component for the booking service
export const BookingServiceProvider: React.FC<BookingServiceProviderProps> = ({ children }) => {
    const { getAccessTokenSilently } = useAuth0();
    const { currentClientId } = useClient();

    const service = React.useMemo(() => {
        return new BookingService(getAccessTokenSilently, currentClientId);
    }, [getAccessTokenSilently, currentClientId]);

    return (
        <BookingServiceContext.Provider value={service}>
            {children}
        </BookingServiceContext.Provider>
    );
};

// Create a hook to use the booking service
export const useBookingService = (): BookingService => {
    const context = React.useContext(BookingServiceContext);
    if (context === undefined) {
        throw new Error('useBookingService must be used within a MemberServiceProvider');
    }
    return context;
};