import React, {useState, useEffect, useCallback} from 'react';
import {Form, Input, Radio, DatePicker, notification, Typography, Divider, Select, FormInstance} from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import {usePaymentService} from "../../services/payments/PaymentServiceProvider";
import {useNavigate} from "react-router-dom";
import {MembershipCategory} from "../../model/members/MembershipCategory";
import { useClientService } from '../../services/clients/ClientServiceProvider';
import { useAuth0 } from '@auth0/auth0-react';

const { Item } = Form;
const { Title } = Typography;
const {Option} = Select;
const { Text } = Typography;



// Custom masked input for sort code
const SortCodeInput: React.FC<{
    value?: string;
    onChange?: (value: string) => void;
    // validateForm: () => void;
}> = ({ value = '', onChange }) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value.replace(/[^\d]/g, '');
        const formattedValue = inputValue
            .slice(0, 6)
            .replace(/(\d{2})(?=\d)/g, '$1-');
        onChange?.(formattedValue);
        // validateForm();
    };

    return (
        <Input
            value={value}
            onChange={handleChange}
            placeholder="11-22-33"
            maxLength={8}
        />
    );
};

// Custom input for account number (numeric only)
const AccountNumberInput: React.FC<{
    value?: string;
    onChange?: (value: string) => void;
    // validateForm: () => void;
}> = ({ value = '', onChange }) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const numericValue = e.target.value.replace(/[^\d]/g, '');
        onChange?.(numericValue);
    };

    return (
        <Input
            value={value}
            onChange={handleChange}
            placeholder="12345678"
            maxLength={8}
        />
    );
};



const NewMemberCustomForm: React.FC<{ form: FormInstance }> = ({ form }) => {
    const navigate = useNavigate();
    const paymentService = usePaymentService();
    const clientService = useClientService();
    
    const [otherSalutation, setOtherSalutation] = useState('');
    const [membershipCategory, setMembershipCategory] = useState<MembershipCategory | null>(null);
    const [availableClients, setAvailableClients] = useState<string[]>([]);
    const [selectedClient, setSelectedClient] = useState<string | null>(null);
    const { isAuthenticated, isLoading, user } = useAuth0();
    
    useEffect(() => {
      if (!isLoading && !isAuthenticated) {
        navigate('/login');
      }
    }, [isAuthenticated, isLoading, navigate]);


    // get clients only on first load of the page
    // if there is only one client, select it
    // set the Select value to the selected client
    useEffect(() => {
        const fetchClients = async () => {
            try {
                const clients = await clientService.getAvailableClients();
                setAvailableClients(clients);

                // If there's only one client, select it automatically
                if (clients.length === 1) {
                    setSelectedClient(clients[0]);
                    // Also set it in the form
                    form.setFieldValue('clientName', clients[0]);
                }
            } catch (error) {
                notification.error({
                    message: 'Error',
                    description: 'Failed to fetch available clients'
                });
            }
        };

        fetchClients();
    }, []);

    useEffect(() => {
        if (selectedClient) {
            form.setFieldValue('clientName', selectedClient);
        }
    }, [selectedClient, form]);

    useEffect(() => {
        console.log('Form values changed:', form.getFieldsValue(true));
    }, [form]);

    const validateForm = useCallback(async () => {
        try {
            await form.validateFields();
        } catch {
        }
    }, [form]);

    
    // TODO: get membership categories from backend
    const [membershipCategories] = useState<MembershipCategory[]>([
        { paymentFrequency: 'Monthly', name: 'Squash', amount: 33.50 },
        { paymentFrequency: 'Monthly', name: 'Corporate', amount: 34.00 },
        { paymentFrequency: 'Monthly', name: 'Student', amount: 43.50 },
        { paymentFrequency: 'Monthly', name: 'Fitness', amount: 49.50 },
        { paymentFrequency: 'Monthly', name: 'Visitor', amount: 55.00 },
        { paymentFrequency: 'Monthly', name: 'Duo', amount: 88.00 },
    ]);
    const [proRataAmount, setProRataAmount] = useState<number | null>(null);

    if (isLoading) {
        return <div>Loading...</div>;
      }
  

    const calculateProRataAmount = (price: number) => {
        const today = new Date();
        const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        const daysInMonth = endOfMonth.getDate();
        const remainingDays = daysInMonth - today.getDate() + 1;

        let totalAmount;
        if (today.getDate() >= 23) {
            totalAmount = price * (remainingDays / daysInMonth + 1);
        } else {
            totalAmount = price * (remainingDays / daysInMonth);
        }

        console.log('Today:', today);
        console.log('End of month:', endOfMonth);
        console.log('Remaining days:', remainingDays);
        console.log('Days in month:', daysInMonth);
        console.log('Price:', price);
        console.log('Total amount:', totalAmount);

        return parseFloat(totalAmount.toFixed(2));
    };

    const handleMembershipCategoryChange = (value: string) => {
        const category = membershipCategories.find(cat => cat.name === value);
        if (category) {
            const proRata = calculateProRataAmount(category.amount);
            setProRataAmount(proRata);
            form.setFieldsValue({ membershipCategory: category });
            setMembershipCategory(category);
        }
    };


    const capitalizeWords = (value: string) => {
        return value.replace(/\b\w/g, char => char.toUpperCase());
    };

    const handleInputChange = (field: string, capitalize: boolean = false) => (e: React.ChangeEvent<HTMLInputElement>) => {
        let { value } = e.target;
        if (capitalize) {
            value = capitalizeWords(value);
        }
        form.setFieldsValue({ [field]: value });
        // validateForm();
    };

    // if there is only one client, select it
    return (
        <Form form={form} layout="vertical"
              initialValues={{
                  country: 'United Kingdom',
                  clientName: selectedClient // Add initial value
              }}>
            <Item name="clientName"
                  label="Club Name"
                  rules={[{ required: true, message: 'Please select a club' }]}>
                <Select
                    value={selectedClient}
                    onChange={(value) => {
                        setSelectedClient(value);
                        form.setFieldValue('clientName', value);
                    }}>
                    {availableClients.map((client) => (
                        <Option key={client} value={client}>
                            {client}
                        </Option>
                    ))}
                </Select>
            </Item>

            <Item
                name="email"
                label="Email Address"
                rules={[{ required: true, type: 'email', message: 'Please enter a valid email address' }]}
            >
                <Input onChange={handleInputChange('email')} />
            </Item>
            <Item name="firstName" label="First Name" rules={[{ required: true, message: 'Please enter your first name' }]}>
                <Input onChange={handleInputChange('firstName', true)} />
            </Item>
            <Item name="surname" label="Surname" rules={[{ required: true, message: 'Please enter your surname' }]}>
                <Input onChange={handleInputChange('surname', true)} />
            </Item>
            <Item name="addressLine1" label="Address Line 1" rules={[{ required: true, message: 'Please enter your address' }]}>
                <Input onChange={handleInputChange('addressLine1', true)} />
            </Item>
            <Item name="addressLine2" label="Address Line 2">
                <Input onChange={handleInputChange('addressLine2', true)} />
            </Item>
            <Item name="townCity" label="Town/City" rules={[{ required: true, message: 'Please enter your town or city' }]}>
                <Input onChange={handleInputChange('townCity', true)} />
            </Item>
            <Item name="countyState" label="County/State" rules={[{ required: true, message: 'Please enter your county or state' }]}>
                <Input onChange={handleInputChange('countyState', true)} />
            </Item>
            <Item name="postcode" label="Postcode" rules={[{ required: true, message: 'Please enter your postcode' }]}>
                <Input onChange={handleInputChange('postcode')} />
            </Item>
            <Item name="country" label="Country" rules={[{ required: true, message: 'Please enter your country' }]}>
                <Input onChange={handleInputChange('country', true)} />
            </Item>
            <Item
                name="dateOfBirth"
                label="Date of Birth"
                rules={[{ required: true, message: 'Please enter your date of birth' }]}
            >
                <DatePicker format="DD/MM/YYYY"  />
            </Item>
            <Item
                name="phoneNumber"
                label="Phone Number"
                rules={[{ required: true, pattern: /^\d+$/, message: 'Please enter a valid phone number' }]}
            >
                <Input onChange={handleInputChange('phoneNumber')} />
            </Item>
            <Item
                name="specialOffers"
                label="Would you like to receive information about special offers and upcoming events at the club?"
                rules={[{ required: true, message: 'Please select an option' }]}
            >
                <Radio.Group >
                    <Radio value="Yes">Yes</Radio>
                    <Radio value="No">No</Radio>
                </Radio.Group>
            </Item>

            <Item
                name="membershipCategory"
                label="Membership Category"
                rules={[{ required: true, message: 'Please select a membership category' }]}
            >
                <Select onChange={handleMembershipCategoryChange} placeholder="Select a membership category">
                    {membershipCategories.map((category) => (
                        <Option key={category.name} value={category.name}>
                            {`${category.name} £${category.amount.toFixed(2)}`}
                        </Option>
                    ))}
                </Select>
            </Item>

            {proRataAmount !== null && (
                <Item>
                    <Text strong style={{ fontSize: '18px' }}>
                        Pro-rata amount due today: £{proRataAmount.toFixed(2)}
                    </Text>
                </Item>
            )}

            <Divider />

            <Title level={3}>Direct Debit Setup</Title>

            <Item name="bankName" label="Bank Name" rules={[{ required: true, message: 'Please enter the bank name' }]}>
                <Input onChange={handleInputChange('bankName', true)} />
            </Item>
            <Item name="accountHolderName" label="Name of Account Holder" rules={[{ required: true, message: 'Please enter the account holder name' }]}>
                <Input onChange={handleInputChange('accountHolderName', true)} />
            </Item>
            <Form.Item
                name="sortCode"
                label="Sort Code"
                rules={[
                    { required: true, message: 'Please enter the sort code' },
                    { pattern: /^\d{2}-\d{2}-\d{2}$/, message: 'Please enter a valid sort code' }
                ]}
            >
                <SortCodeInput onChange={handleInputChange}/>
            </Form.Item>

            <Item
                name="accountNumber"
                label="Account Number"
                rules={[
                    { required: true, message: 'Please enter the account number' },
                    { pattern: /^\d{8}$/, message: 'Please enter a valid 8-digit account number' }
                ]}
            >
                <AccountNumberInput onChange={handleInputChange} />
            </Item>
        </Form>
    );
};

export default NewMemberCustomForm;