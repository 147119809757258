import {ClubBookingApiClient} from "../../common/api/ClubBookingApiClient";

export class ClientService extends ClubBookingApiClient {
    async getAvailableClients(): Promise<string[]> {
        return this.get<string[]>(
            `/clients`,
            undefined,
            { skipClientHeader: true }
        );
    }
}