// NewMemberRegistration.tsx
import React, {useCallback, useEffect, useState} from 'react';
import { Steps, Button, message, Form, notification } from 'antd';
import NewMemberCustomForm from './NewMemberCustomForm';
import HealthForm from './health/HealthForm';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import { useMemberService } from '../../services/members/MemberServiceProvider';
import { useNavigate } from 'react-router-dom';
import { CreateMemberRequest } from '../../requests/member/CreateMemberRequest';
import HealthFormSubmissionRequest from '../../requests/member/health/HealthFormSubmissionRequest';
import { useClient } from '../../common/ClientProvider';
import { CreateDirectDebitRequest } from '../../requests/payments/CreateDirectDebitRequest';

const { Step } = Steps;

const NewMemberRegistration: React.FC = () => {
    const navigate = useNavigate();
    const [currentStep, setCurrentStep] = useState(0);
    const [form] = Form.useForm();
    const [memberFormData, setMemberFormData] = useState(null);
    const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true);
    const memberService = useMemberService();
    const client = useClient();
    const validateForm = useCallback(async () => {
        try {
            await form.validateFields();
            setIsSubmitButtonDisabled(false);
        } catch {
            setIsSubmitButtonDisabled(true);
        }
    }, [form]);

    useEffect(() => {
        console.log('Effect triggered', { memberFormData, currentStep });
        if (memberFormData) {
            console.log('Setting form values', memberFormData);
            form.setFieldsValue(memberFormData);
        }
    }, [currentStep, memberFormData, form]);

    const steps = [
        {
            title: 'Member Details',
            content: <NewMemberCustomForm form={form} />,
        },
        {
            title: 'Health Questionnaire',
            content: <HealthForm form={form} />,
        }
    ];

    const next = async () => {
        try {
            // Validate current step's fields
            const values = await form.validateFields();
            setMemberFormData(prev => ({ ...prev || {}, ...values }));
            setCurrentStep(currentStep + 1);
        } catch (error) {
            console.error('Validation failed:', error);
        }
    };

    const prev = () => {
        setCurrentStep(currentStep - 1);
    };

    const handleSubmit = async () => {
        try {
            const finalValues = await form.validateFields();
            const allFormData = { ...memberFormData || {}, ...finalValues };
            
            // First create the member
            const memberRequest = createMemberRequest(allFormData, client.currentClientId);
            await memberService.create(memberRequest);
        
            
            message.success('Registration completed successfully!');
            navigate(`/dashboard`);
        } catch (error) {
            if (error instanceof Error) {
                notification.error({
                    message: 'Error',
                    description: error.message || 'An error occurred while creating the member',
                });
            } else {
                notification.error({
                    message: 'Error',
                    description: 'An unknown error occurred while creating the member',
                });
            }
        }
        throw new Error('Failed to create member');
    };

    const createDirectDebitRequest = (formData: any): CreateDirectDebitRequest => {
        const request: CreateDirectDebitRequest = {
            accountHolderName: formData.accountHolderName,
            bankName: formData.bankName,
            accountNumber: formData.accountNumber,
            sortCode: formData.sortCode.replace(/-/g, '')
        };

        return request;
    }

    const createMemberRequest = (formData: any, clientName: string): CreateMemberRequest => {
        const healthFormRequest = createHealthFormRequest(formData);
        const directDebitRequest = createDirectDebitRequest(formData);
        const request: CreateMemberRequest = {
            name: `${formData.firstName} ${formData.surname}`,
            username: formData.email,
            countyState: formData.countyState,
            dateOfBirth: formData.dateOfBirth,
            emailAddress: formData.email,
            addressLine1: formData.addressLine1,
            addressLine2: formData.addressLine2,
            townCity: formData.townCity,
            postZipCode: formData.postcode,
            phoneNumber: formData.phoneNumber,
            country: formData.country,
            receiveEmails: formData.specialOffers === 'Yes',
            status: 'Active',
            membershipCategory: formData.membershipCategory,
            clientName,
            healthForm: healthFormRequest,
            directDebit: directDebitRequest
        };

        return request;
    };

    const createHealthFormRequest = (values: any) => {
        const request: HealthFormSubmissionRequest = {
            answers: [
                { question: 'heartCondition', answer: values.heartCondition },
                { question: 'chestPain', answer: values.chestPain },
                { question: 'chestPainLastMonth', answer: values.chestPainLastMonth },
                { question: 'balanceConsciousness', answer: values.balanceConsciousness },
                { question: 'boneJointProblem', answer: values.boneJointProblem },
                { question: 'bloodPressureMedication', answer: values.bloodPressureMedication },
                { question: 'medicalConditions', answer: values.medicalConditions.join('|') },
                { question: 'recentSurgery', answer: values.recentSurgery },
                { question: 'pregnant', answer: values.pregnant },
                { question: 'recentBaby', answer: values.recentBaby },
                { question: 'currentlySick', answer: values.currentlySick },
                { question: 'agreement', answer: values.agreement.toString() },
                { question: 'printName', answer: values.printName },
                { question: 'date', answer: values.date },
            ],
        }

        return request;
    }
    return (
        <div>
            <Steps current={currentStep} style={{ marginBottom: 24 }}>
                {steps.map(item => (
                    <Step key={item.title} title={item.title} />
                ))}
            </Steps>

            <div className="steps-content">{steps[currentStep].content}</div>

            <div className="steps-action" style={{ marginTop: 24 }}>
                {currentStep > 0 && (
                    <Button style={{ margin: '0 8px' }} onClick={prev} icon={<LeftOutlined />}>
                        Previous
                    </Button>
                )}
                {currentStep < steps.length - 1 && (
                    <Button type="primary" onClick={next} icon={<RightOutlined />}>
                        Next
                    </Button>
                )}
                {currentStep === steps.length - 1 && (
                    <Button type="primary" onClick={handleSubmit}>
                        Submit
                    </Button>
                )}
            </div>
        </div>
    );
};

export default NewMemberRegistration;